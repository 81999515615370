import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios, { AxiosError } from 'axios';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../config/constants';
import strings from '../../config/strings';
import { AlertContext } from '../../context/alert.context';
import userContext from '../../context/user.context';
import { getLocalizedErrorMessage } from '../../utils/functions';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

export default function Login() {
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const navigate = useNavigate();
    const auth = useContext(userContext);

    const [showVerification, setShowVerification] = useState(false);
    const [emailToVerify, setEmailToVerify] = useState('');
    const [verificationCode, setEmailVerificationCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const alert = useContext(AlertContext);
    const startVerification = async (email: string) => {
        setEmailToVerify(email);
        // await axios.post(`${constants.API_URL}/member/v2/send_verification_email`, { email });
        setShowVerification(true);
    };

    const onLoginSuccess = ({ member_id, refresh_token, token }: { member_id: string; token: string; refresh_token: string }) => {
        localStorage.setItem(strings.$GH_TOKEN, token);
        localStorage.setItem(strings.$GH_REFRESH_TOKEN, refresh_token);

        auth.login(member_id, token, refresh_token);
        navigate('/expert');
    };

    const startLoading = () => setIsLoading(true);
    const endLoading = () => setIsLoading(false);
    const handleVerify = useCallback(async () => {
        try {
            const response = await axios.post(`${constants.API_URL}/member/check_otp`, {
                email: emailToVerify,
                code: verificationCode,
            });
            auth.login('_id', response.data.token, response.data.refresh_token);
            await axios.post(`${constants.API_URL}/user`);
            if (response.status === 200) {
                navigate('/expert');
            }
        } catch (error: any) {
            console.log(error);
            alert?.showAlert(error.response.data.errors || [error.response.data.message.he], 'error');
        }
    }, [navigate, emailToVerify, verificationCode, auth, alert]);

    const onError = (error: any) => {
        console.log('onError', error);
        if (error instanceof AxiosError) {
            if (error.response?.status === 666) {
                startVerification(error.response.data.email);
            } else {
                alert?.showAlert(
                    error.response?.data.errors
                        ?.map((error: any) => {
                            return error['he'] ?? error;
                        })
                        ?.join('\n') ||
                        getLocalizedErrorMessage(error, 'he') ||
                        error.response?.data.message?.he ||
                        error.message,
                    'error'
                );
            }
        }
    };
    return (
        <>
            <div dir='rtl' className='container mx-auto max-w-lg'>
                <div className='flex flex-col items-center mt-8 '>
                    <div className='m-4'>
                        {isLoading ? <CircularProgress size={30} /> : <Avatar sx={{ width: 80, height: 80 }} src={require('../../assets/grouphug_1024.png')} />}
                    </div>
                    <h1 className='text-4xl font-base text-center mb-2'>המומחים של GroupHug</h1>
                    <h2 className='text-xl'>{isLogin ? 'התחברות' : 'הרשמה'}</h2>
                    <div className='w-full mt-2 flex flex-col items-center'>
                        {showVerification ? (
                            <div className='flex flex-col w-[350px] items-center gap-4 my-6'>
                                <Typography variant='h6' textAlign='center'>
                                    שלחנו לך קוד אימות לאימייל
                                </Typography>

                                <TextField
                                    placeholder='קוד אימות'
                                    fullWidth
                                    variant='standard'
                                    inputMode='numeric'
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    value={verificationCode}
                                    onChange={(e) => setEmailVerificationCode(e.target.value)}
                                />

                                <Button onClick={handleVerify} variant='outlined'>
                                    אימות
                                </Button>
                            </div>
                        ) : isLogin ? (
                            <LoginForm onLoginSuccess={onLoginSuccess} onError={onError} startLoading={startLoading} endLoading={endLoading} />
                        ) : (
                            <RegisterForm onRegisterSuccess={(email: string) => startVerification(email)} startLoading={startLoading} endLoading={endLoading} onError={onError} />
                        )}
                    </div>

                    {showVerification ? null : (
                        <div className='flex flex-col items-center gap-2'>
                            <Link href='#' onClick={() => setIsLogin((prev) => !prev)} variant='body2'>
                                {isLogin ? 'עדיין אין לך חשבון? לחץ/י כאן' : 'כבר יש לך חשבון? לחץ/י כאן'}
                            </Link>
                            <Button onClick={() => navigate('/reset_password')}>שכחת סיסמא?</Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
